<template>
    <div
        class="relative mb-4 flex h-auto w-full cursor-pointer justify-around rounded-full border text-center"
    >
        <div
            class="-mr-5 w-3/5 rounded-full"
            :class="[
                {
                    'z-10 bg-white': !isActiveLeft,
                    'z-20 bg-neutral-darker text-white': isActiveLeft,
                },
            ]"
        >
            <div class="h-full" :class="{ 'mr-10': !isActiveLeft }" @click="toggleClick(true)">
                {{ leftLabel }}
            </div>
        </div>
        <div
            class="-ml-5 w-3/5 rounded-full"
            :class="[
                {
                    'z-10 bg-white': isActiveLeft,
                    'z-20 bg-neutral-darker text-white': !isActiveLeft,
                },
            ]"
        >
            <div class="h-full" :class="{ 'ml-10': isActiveLeft }" @click="toggleClick(false)">
                {{ rightLabel }}
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({
    props: {
        leftLabel: String,
        rightLabel: String,
    },
})
export default class InputToggle extends Vue {
    isActiveLeft = true;
    toggleClick(isLeftButton) {
        this.isActiveLeft = isLeftButton.valueOf();
        this.$emit('toggle', isLeftButton.valueOf());
    }
}
</script>
