<template>
    <div class="relative w-full text-left">
        <label
            v-if="label"
            class="text-sm transition-all duration-500"
            :class="{
                'text-primary': focused,
                'text-neutral-darkest': !focused,
                'text-error': $store.getters.hasError(name, scope),
            }"
        >
            {{ label }}
        </label>
        <div class="relative flex cursor-pointer flex-col justify-center">
            <div
                tabindex="0"
                class="w-full appearance-none truncate rounded-lg border border-neutral-lighter py-2 pl-4 pr-12 text-neutral-darkest outline-none transition-all duration-500 md:border-2"
                :class="{
                    'border-primary': focused,
                    'border-error': $store.getters.hasError(name, scope),
                    'text-opacity-25': !value,
                }"
                @focus="focused = true"
                @blur="focused = false"
                @click="showPicker = true"
            >
                {{ value ? formattedValue : format }}
            </div>
            <Icon
                class="pointer-events-none absolute right-0 mr-4 transition-all duration-500"
                :class="{
                    'text-primary': focused,
                    'text-neutral-darkest': !focused,
                    'text-error': $store.getters.hasError(name, scope),
                }"
                :icon="['far', 'calendar']"
            />
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="$store.getters.hasError(name, scope)"
                    class="absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"
                >
                    {{ $store.getters.getError(name, scope) }}
                </div>
            </transition>
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <DatePicker
                    v-if="showPicker"
                    v-on-clickaway="hidePicker"
                    class="absolute bottom-0 z-10 translate-y-full transform rounded-3xl"
                    :value="value"
                    @input="onInput"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import DatePicker from '@/components/input/DatePicker';
import { directive as onClickaway } from 'vue-clickaway';
import moment from 'moment-timezone';
import { DATE_FORMAT, VALIDATION_REMOVE_ERROR } from '@/constants';

@Component({
    components: {
        DatePicker,
    },
    props: {
        format: {
            default: DATE_FORMAT,
            type: String,
        },
        label: String,
        name: String,
        scope: {
            default: 'default',
            type: String,
        },
        value: Object,
    },
    directives: {
        onClickaway: onClickaway,
    },
})
export default class InputDate extends Vue {
    focused = false;
    showPicker = false;

    get formattedValue() {
        return moment(this.value).format(this.format);
    }

    onInput(value) {
        if (this.$store.getters.hasError(this.name, this.scope)) {
            this.$store.commit(VALIDATION_REMOVE_ERROR, {
                field: this.name,
                scope: this.scope,
            });
        }
        this.$emit('input', value);
        this.hidePicker();
    }

    hidePicker() {
        if (this.showPicker) {
            this.showPicker = false;
        }
    }
}
</script>
