var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative mb-4 flex h-auto w-full cursor-pointer justify-around rounded-full border text-center"},[_c('div',{staticClass:"-mr-5 w-3/5 rounded-full",class:[
            {
                'z-10 bg-white': !_vm.isActiveLeft,
                'z-20 bg-neutral-darker text-white': _vm.isActiveLeft,
            },
        ]},[_c('div',{staticClass:"h-full",class:{ 'mr-10': !_vm.isActiveLeft },on:{"click":function($event){return _vm.toggleClick(true)}}},[_vm._v(" "+_vm._s(_vm.leftLabel)+" ")])]),_c('div',{staticClass:"-ml-5 w-3/5 rounded-full",class:[
            {
                'z-10 bg-white': _vm.isActiveLeft,
                'z-20 bg-neutral-darker text-white': !_vm.isActiveLeft,
            },
        ]},[_c('div',{staticClass:"h-full",class:{ 'ml-10': _vm.isActiveLeft },on:{"click":function($event){return _vm.toggleClick(false)}}},[_vm._v(" "+_vm._s(_vm.rightLabel)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }