import {
    CLIENT,
    GUEST_GUARD,
    CLIENT_GUARD,
    INVITE_SET,
    THEME_STORAGE_KEY,
    INVITE_ACCEPT,
    INVITE_REMOVE,
} from '@/constants';
import store from '@/store';

export default {
    path: '/',
    meta: {
        theme: CLIENT,
    },
    component: () => import('@/pages/client/Wrapper'),
    children: [
        {
            path: '/',
            name: 'ClientHome',
            meta: {
                order: 1,
                guard: GUEST_GUARD,
            },
            redirect: 'auth/signin',
        },
        {
            path: 'auth/signin/:tempToken?',
            name: 'ClientAuthLogIn',
            meta: {
                order: 2,
            },
            component: () => import('@/pages/common/AuthLogIn'),
        },
        {
            path: 'auth/signup',
            name: 'ClientAuthSignUp',
            redirect: 'professional/auth/signup',
        },
        {
            path: 'invite/:slug',
            name: 'SignUpWithProfessional',
            async beforeEnter(to, from, next) {
                store.commit(INVITE_SET, to.params.slug);

                if (!store.getters.isAuthenticated) {
                    // clearing local storage prevents redirecting to
                    // client calendar in case theme was set
                    localStorage.removeItem(THEME_STORAGE_KEY);
                    next({ name: 'ClientAuthSignUp' });
                    return;
                }

                const response = await store.dispatch(INVITE_ACCEPT);

                if (response.status === 200) {
                    store.commit(INVITE_REMOVE);
                }

                next({ path: `/professionals` });
            },
        },
        {
            path: '/auth/confirmEmail',
            name: 'ConfirmationEmail',
            meta: {
                order: 3,
            },
            component: () => import('@/pages/common/ConfirmEmail.vue'),
        },
        {
            path: 'auth/forgot',
            name: 'ClientAuthForgot',
            meta: {
                order: 4,
            },
            component: () => import('@/pages/client/auth/AuthForgot'),
        },
        {
            path: 'auth/reset/:token',
            name: 'ClientAuthReset',
            meta: {
                order: 5,
            },
            component: () => import('@/pages/client/auth/AuthReset'),
        },
        {
            path: 'auth/activate/:id',
            name: 'ClientAuthActivate',
            meta: {
                order: 6,
            },
            component: () => import('@/pages/client/auth/AuthActivate'),
        },
        {
            path: '/privacy',
            name: 'ClientPrivacyPolicy',
            component: () => import('@/pages/PrivacyPolicy'),
        },
        {
            path: '/terms',
            name: 'ClientTermsAndConditions',
            component: () => import('@/pages/TermsAndConditions'),
        },
        {
            path: 'calendar',
            name: 'ClientCalendar',
            meta: {
                order: 9,
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/client/ClientCalendar'),
        },
        {
            path: 'professionals/:id/accept',
            name: 'ClientProfessionalsInvitation',
            meta: {
                order: 10,
                guard: CLIENT_GUARD,
                forceRouteTheme: true,
            },
            component: () => import('@/pages/client/Professionals'),
        },
        {
            path: 'professionals',
            name: 'ClientProfessionals',
            meta: {
                order: 11,
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/client/Professionals'),
        },
        {
            path: 'profile/email/verify',
            name: 'ClientProfileEmailVerify',
            meta: {
                order: 12,
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/common/profile/Profile'),
        },
        {
            path: 'profile',
            name: 'ClientProfile',
            meta: {
                order: 12,
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/common/profile/Profile'),
        },
        {
            path: 'profile/edit',
            name: 'ClientProfileEdit',
            meta: {
                order: 13,
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/common/profile/ProfileEdit'),
            children: [
                {
                    path: 'personal',
                    name: 'ClientProfilePersonal',
                    meta: {
                        guard: CLIENT_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfilePersonal'),
                },
                {
                    path: 'social',
                    name: 'ClientProfileSocial',
                    meta: {
                        guard: CLIENT_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfileSocial'),
                },
                {
                    path: 'password',
                    name: 'ClientProfilePassword',
                    meta: {
                        guard: CLIENT_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfilePassword'),
                },
                {
                    path: 'notifications',
                    name: 'ClientProfileNotifications',
                    meta: {
                        guard: CLIENT_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfileNotifications'),
                },
            ],
        },
        {
            path: 'settings',
            name: 'ClientSettings',
            meta: {
                order: 14,
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/common/settings/Settings'),
        },
        {
            path: 'report-problem',
            name: 'ClientReportProblem',
            meta: {
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/common/profile/ProfileReportProblem'),
        },
        {
            path: 'suggest-improvement',
            name: 'ClientSuggestImprovement',
            meta: {
                guard: CLIENT_GUARD,
            },
            component: () => import('@/pages/common/profile/ProfileSuggestImprovement'),
        },
    ],
};
