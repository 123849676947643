import axios from 'axios';
import {
    REQUEST_SET_BASE_URL,
    REQUEST_START_LOADING,
    REQUEST_END_LOADING,
    REQUEST,
    REQUEST_HEADERS,
    VALIDATION_CREATE_MESSAGE,
    VALIDATION_SET_BAG,
    AUTH_SET_TOKEN,
    PROFILE_SET_USER,
} from '@/constants';

export default {
    state: {
        baseURL: '/',
        loaders: [],
    },
    getters: {
        isLoading:
            (state) =>
            (scope = 'default') => {
                return state.loaders.includes(scope);
            },
    },
    mutations: {
        [REQUEST_SET_BASE_URL](state, baseURL) {
            state.baseURL = baseURL;
        },
        [REQUEST_END_LOADING](state, scope = 'default') {
            state.loaders = state.loaders.filter((loader) => loader !== scope);
        },
        [REQUEST_START_LOADING](state, scope = 'default') {
            state.loaders.push(scope);
        },
    },
    actions: {
        async [REQUEST]({ commit, dispatch, state }, config) {
            try {
                commit(REQUEST_START_LOADING, config.scope);
                dispatch(REQUEST_HEADERS);
                let response = await axios({
                    ...config,
                    baseURL: config.baseURL || state.baseURL,
                });
                commit(REQUEST_END_LOADING);
                return response;
            } catch (error) {
                let message = 'network_errors.unknown';
                if (error && Object.prototype.hasOwnProperty.call(error, 'response')) {
                    if (error.response === undefined) {
                        message = 'network_errors.cors';
                    } else {
                        message = `network_errors.${error.response.status}`;
                    }
                }

                // Hide Toast by default for 401s
                if (message === 'network_errors.401') {
                    config.hideToast = true;
                    if (error.response?.data?.data?.errors[0]?.message === 'Unauthenticated.') {
                        commit(AUTH_SET_TOKEN, null);
                        commit(PROFILE_SET_USER, null);
                    }
                }

                if (message === 'network_errors.422') {
                    commit(VALIDATION_SET_BAG, {
                        bag: error.response.data.data.errors,
                        scope: config.scope,
                    });
                } else {
                    // Overwrite the toast message when a more specific message is available
                    message = error.response?.data?.data?.errors[0]?.message || message;
                }

                if (!config.hideToast) {
                    dispatch(VALIDATION_CREATE_MESSAGE, { type: 'error', message });
                }
                commit(REQUEST_END_LOADING, config.scope);
                return error.response || {};
            }
        },
        [REQUEST_HEADERS]({ rootState }) {
            delete axios.defaults.headers.common['Authorization'];
            if (rootState.auth.token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${rootState.auth.token}`;
            }
            const lang = window.localStorage.getItem('SELECTED_LANGUAGE');
            if (lang) {
                axios.defaults.headers.common['Accept-Language'] = lang;
            }
        },
    },
};
