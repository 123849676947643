var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full text-left"},[(_vm.label)?_c('label',{staticClass:"text-sm transition-all duration-500",class:{
            'text-primary': _vm.focused,
            'text-neutral-darkest': !_vm.focused,
            'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"relative flex flex-col justify-center text-sm"},[_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.value),function(tag,index){return _c('div',{key:tag + index,staticClass:"m-1 flex items-center rounded-lg bg-neutral-lighter bg-opacity-50 p-1 px-2"},[_c('span',[_vm._v(_vm._s(tag))]),_c('button',{staticClass:"px-1 focus:outline-none",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeTag(tag)}}},[_c('Icon',{attrs:{"icon":['far', 'xmark']}})],1)])}),0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"w-full appearance-none border-b border-neutral-lighter px-4 py-2 font-light placeholder-neutral-darker outline-none transition-all duration-500",class:{
                'pl-12': _vm.icon,
                'border-primary': _vm.focused,
                'border-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
            },attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.text)},on:{"focus":function($event){_vm.focused = true},"blur":_vm.onBlur,"input":[function($event){if($event.target.composing)return;_vm.text=$event.target.value},_vm.onInput],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.removeLastTag()}}}),(_vm.icon)?_c('Icon',{staticClass:"absolute left-0 ml-4 transition-all duration-500",class:{
                'text-primary': _vm.focused,
                'text-neutral-darkest': !_vm.focused,
                'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
            },attrs:{"icon":['far', _vm.icon]}}):_vm._e(),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.$store.getters.hasError(_vm.name, _vm.scope))?_c('div',{staticClass:"absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"},[_vm._v(" "+_vm._s(_vm.$store.getters.getError(_vm.name, _vm.scope))+" ")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }