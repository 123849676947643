var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full text-left"},[(_vm.label)?_c('label',{staticClass:"text-sm transition-all duration-300",class:{
            'text-primary': _vm.focused,
            'text-neutral-darkest': !_vm.focused,
            'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"relative flex cursor-pointer flex-col justify-center"},[_c('div',{staticClass:"w-full appearance-none truncate rounded border border-neutral-lighter py-2 pl-4 pr-10 text-sm text-neutral-darkest outline-none transition-all duration-300 md:rounded-lg md:border-2",class:{
                'border-primary': _vm.focused,
                'border-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
                'text-opacity-25': !_vm.selectedLabel,
            },attrs:{"tabindex":"0"},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}},[_vm._v(" "+_vm._s(_vm.selectedLabel || _vm.placeholder)+" ")]),_c('Icon',{staticClass:"pointer-events-none absolute right-0 mr-4 transition-all duration-300",class:{
                'text-primary': _vm.focused,
                'text-neutral-darkest': !_vm.focused,
                'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
            },attrs:{"icon":['far', 'chevron-down']}}),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.$store.getters.hasError(_vm.name, _vm.scope))?_c('div',{staticClass:"absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-300"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$store.getters.getError(_vm.name, _vm.scope)))+" ")]):_vm._e()]),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.focused)?_c('div',{staticClass:"absolute right-0 top-14 z-10 flex w-full flex-col gap-1 overflow-auto rounded-lg border border-neutral-lighter bg-white p-1 text-sm transition-all duration-300 md:border-2",staticStyle:{"max-height":"20rem"}},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"truncate rounded px-4 py-2 transition-all duration-300",class:[
                        _vm.value === option.value
                            ? 'border border-neutral-lighter bg-neutral-lightest'
                            : 'hover:bg-neutral-lightest',
                    ],on:{"mousedown":function($event){return _vm.selectOption(option.value)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }