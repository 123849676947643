import { PROFESSIONAL, GUEST_GUARD, PROFESSIONAL_GUARD } from '@/constants';

export default {
    path: '/professional',
    meta: {
        theme: PROFESSIONAL,
    },
    component: () => import('@/pages/professional/Wrapper'),
    children: [
        {
            path: '/',
            name: 'ProfessionalHome',
            meta: {
                order: 1,
                guard: GUEST_GUARD,
            },
            redirect: '/auth/signin',
        },
        {
            path: 'auth/signin',
            name: 'ProfessionalAuthLogIn',
            meta: {
                order: 2,
            },
            redirect: '/auth/signin',
        },
        {
            path: 'auth/signup',
            name: 'ProfessionalAuthSignUp',
            meta: {
                order: 3,
            },
            component: () => import('@/pages/professional/auth/AuthSignUp'),
        },
        {
            path: 'auth/confirmEmail',
            name: 'ConfirmationEmail',
            meta: {
                order: 3,
                guard: GUEST_GUARD,
            },
            component: () => import('@/pages/common/ConfirmEmail.vue'),
        },
        {
            path: 'auth/forgot',
            name: 'ProfessionalAuthForgot',
            meta: {
                order: 4,
            },
            component: () => import('@/pages/professional/auth/AuthForgot'),
        },
        {
            path: 'auth/reset/:token',
            name: 'ProfessionalAuthReset',
            meta: {
                order: 5,
            },
            component: () => import('@/pages/professional/auth/AuthReset'),
        },
        {
            path: 'auth/activate/:id',
            name: 'ProfessionalAuthActivate',
            meta: {
                order: 6,
            },
            component: () => import('@/pages/professional/auth/AuthActivate'),
        },
        {
            path: '/privacy',
            name: 'ProfessionalPrivacyPolicy',
            component: () => import('@/pages/PrivacyPolicy'),
        },
        {
            path: '/terms',
            name: 'ProfessionalTermsAndConditions',
            component: () => import('@/pages/TermsAndConditions'),
        },
        {
            path: 'calendar',
            name: 'ProfessionalCalendar',
            meta: {
                order: 10,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/professional/ProfessionalCalendar'),
        },
        {
            path: 'event-registrations/:id/decline',
            name: 'EventRegistrationDecline',
            meta: {
                order: 10,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/professional/ProfessionalCalendar'),
        },
        {
            path: 'vouchers',
            name: 'ProfessionalVouchers',
            meta: {
                order: 11,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/professional/Vouchers'),
        },
        {
            path: 'vouchers/edit',
            name: 'ProfessionalVoucherSettings',
            meta: {
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/professional/VoucherSettings'),
        },
        {
            path: 'clients',
            name: 'ProfessionalClients',
            meta: {
                order: 12,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/professional/Clients'),
        },
        {
            path: 'clients/:id',
            name: 'ProfessionalClientsProfile',
            component: () => import('@/pages/professional/ClientsProfile'),
        },
        {
            path: 'groups/:id',
            name: 'ProfessionalGroupOverview',
            component: () => import('@/pages/professional/GroupOverview'),
        },
        {
            path: 'profile/email/verify',
            name: 'ProfessionalProfileEmailVerify',
            meta: {
                order: 13,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/common/profile/Profile'),
        },
        {
            path: 'profile',
            name: 'ProfessionalProfile',
            meta: {
                order: 13,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/common/profile/Profile'),
        },
        {
            path: 'profile',
            name: 'ProfessionalProfileEdit',
            meta: {
                order: 14,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/common/profile/ProfileEdit'),
            children: [
                {
                    path: 'professional',
                    name: 'ProfessionalProfileProfessional',
                    meta: {
                        guard: PROFESSIONAL_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfileProfessional'),
                },
                {
                    path: 'personal',
                    name: 'ProfessionalProfilePersonal',
                    meta: {
                        guard: PROFESSIONAL_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfilePersonal'),
                },
                {
                    path: 'social',
                    name: 'ProfessionalProfileSocial',
                    meta: {
                        guard: PROFESSIONAL_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfileSocial'),
                },
                {
                    path: 'password',
                    name: 'ProfessionalProfilePassword',
                    meta: {
                        guard: PROFESSIONAL_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfilePassword'),
                },
                {
                    path: 'notifications',
                    name: 'ProfessionalProfileNotifications',
                    meta: {
                        guard: PROFESSIONAL_GUARD,
                    },
                    component: () => import('@/pages/common/profile/ProfileNotifications'),
                },
            ],
        },
        {
            path: 'settings',
            name: 'ProfessionalSettings',
            meta: {
                order: 15,
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/common/settings/Settings'),
        },
        {
            path: 'report-problem',
            name: 'ProfessionalReportProblem',
            meta: {
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/common/profile/ProfileReportProblem'),
        },
        {
            path: 'suggest-improvement',
            name: 'ProfessionalSuggestImprovement',
            meta: {
                guard: PROFESSIONAL_GUARD,
            },
            component: () => import('@/pages/common/profile/ProfileSuggestImprovement'),
        },
    ],
};
