<template>
    <div
        ref="modal"
        class="fixed inset-0 z-50 flex h-screen flex-col items-center justify-end sm:justify-center"
        tabindex="0"
        @keydown.esc="close()"
    >
        <transition enter-class="opacity-0" leave-to-class="opacity-0">
            <div
                v-if="ready"
                class="absolute inset-0 bg-black bg-opacity-50 px-2 transition-all duration-500"
                @click.prevent="close"
            ></div>
        </transition>
        <transition
            enter-class="translate-y-full opacity-0"
            enter-active-class="transition-all duration-500 transform"
            leave-to-class="translate-y-full opacity-0"
            leave-active-class="transition-all duration-500 transform"
        >
            <form
                v-show="ready"
                data-cy="modal"
                class="relative h-full w-full overflow-x-auto shadow sm:h-auto sm:rounded-3xl"
                :class="max_width"
                :style="{ transform: panDelta ? `translateY(${panDelta}px)` : undefined }"
                @submit.prevent="$emit('submit')"
            >
                <div
                    ref="handle"
                    class="sticky top-0 z-50 flex items-center justify-between border-b border-neutral-lighter bg-white p-6"
                >
                    <slot name="header" />
                    <button
                        type="button"
                        class="ml-5 focus:outline-none sm:order-last"
                        @click.prevent="close"
                    >
                        <Icon :icon="['far', 'xmark']" class="block text-2xl" />
                    </button>
                </div>
                <div class="hsm:p-6 h-full overflow-auto bg-white p-6 pb-40 sm:h-auto sm:p-8">
                    <slot />
                </div>
                <div
                    class="fixed bottom-0 w-full border-t border-neutral-lighter bg-white p-6 sm:hidden"
                >
                    <slot name="footer" />
                </div>
            </form>
        </transition>
        <a tabindex="0" class="fixed top-0 overflow-hidden" @focus="$refs.modal.focus()" />
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import Hammer from 'hammerjs';
import { HELPERS_SLEEP } from '@/constants';

@Component
export default class Modal extends Vue {
    @Prop({ default: 'sm:max-w-lg' }) max_width;
    ready = false;
    panDelta = 0;

    mounted() {
        this.ready = true;
        this.$refs.modal.focus();

        // Add Hammer.js swipe down event for closing the sheet
        const manager = new Hammer.Manager(this.$refs.handle);
        const Pan = new Hammer.Pan({ direction: 16 });
        manager.add(Pan);

        this.panDelta = 0;

        manager.on('pandown', (e) => {
            this.panDelta = e.deltaY;
        });

        manager.on('panend', (e) => {
            if (e.deltaY > 200) {
                this.close();
            } else {
                this.panDelta = 0;
            }
        });
    }

    created() {
        // Close modal when navigating back
        const unregisterRouterGuard = this.$router.beforeEach((_, __, next) => {
            this.close();
            next();
        });

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard();
        });
    }

    async close() {
        this.ready = false;
        await this.$store.dispatch(HELPERS_SLEEP, 500);
        this.$emit('close');
    }
}
</script>
