<template>
    <div
        class="relative mx-auto rounded-full bg-primary"
        :class="{
            'h-8 w-8': size === 'xs',
            'h-12 w-12': size === 'small',
            'h-16 w-16': size === 'medium',
            'h-24 w-24': size === 'large',
            'h-32 w-32': size === 'xl',
        }"
    >
        <span
            v-if="!profile || !profile.avatar"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform font-bold text-white"
            :class="{
                'text-base': size === 'xs',
                'text-2xl': size === 'small',
                'text-3xl': size === 'medium',
                'text-4xl': size === 'large',
                'text-5xl': size === 'xl',
            }"
        >
            {{ initials }}
        </span>
        <img v-else :src="profile.avatar" class="h-full w-full rounded-full object-cover" />
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Avatar extends Vue {
    @Prop({
        type: Object,
        default: () => {},
    })
    profile;
    @Prop({ type: String, default: 'medium' }) size;

    get initials() {
        if (!this.profile?.full_name) return '';
        const words = this.profile.full_name.split(' ');
        return words.length > 1 ? words[0].charAt(0) + words[1].charAt(0) : words[0].charAt(0);
    }
}
</script>

<style lang="postcss"></style>
