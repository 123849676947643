var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{staticClass:"pointer-events-none fixed right-0 top-0 z-50 flex w-full flex-col items-end overflow-hidden p-2 sm:bottom-0 sm:flex-col-reverse",attrs:{"enter-class":"translate-x-full opacity-0","leave-to-class":"translate-x-full opacity-0"}},_vm._l((_vm.messages),function(item){return _c('div',{key:item.id,staticClass:"pointer-events-auto relative left-0 ml-16 mt-2 flex transform items-center rounded border-l-4 bg-white p-4 shadow transition-all duration-500 md:ml-0",class:{
            'border-error': item.type === 'error',
            'border-success': item.type === 'success',
        }},[_c('Icon',{class:{
                'text-error': item.type === 'error',
                'text-success': item.type === 'success',
            },attrs:{"icon":['far', _vm.icon[item.type]],"size":"lg"}}),_c('div',{staticClass:"pl-4 leading-tight"},[_c('div',{staticClass:"pb-1 font-bold"},[_vm._v(_vm._s(_vm.$t('common.' + item.type)))]),_c('span',[_vm._v(_vm._s(_vm.$t(item.message)))])]),_c('Icon',{staticClass:"mx-3",attrs:{"icon":['far', 'xmark']},on:{"click":function($event){return _vm.hide(item)}}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }