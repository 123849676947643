<template>
    <div
        :class="{ 'border-primary bg-primary': isActive }"
        class="relative m-0 h-6 w-12 flex-shrink-0 cursor-pointer self-center rounded-full border-2 border-neutral-lighter bg-neutral-lighter p-0 duration-300 ease-in-out"
        @click="toggleClick"
    >
        <div
            :class="{ 'translate-x-6': isActive }"
            class="absolute left-0 right-0 m-0 h-5 w-5 transform rounded-full bg-white p-0 duration-300 ease-in-out"
        ></div>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SimpleToggle extends Vue {
    @Prop({ default: false }) initial;
    data() {
        return {
            isActive: false,
        };
    }
    mounted() {
        this.isActive = this.initial;
    }

    toggleClick() {
        this.isActive = !this.isActive;
        this.$emit('toggle', this.isActive);
    }
}
</script>

<style scoped lang="postcss">
input {
    opacity: 0;
    width: 0;
    height: 0;
}
</style>
