<template>
    <div class="relative w-full text-left">
        <label
            v-if="label"
            class="text-sm transition-all duration-300"
            :class="{
                'text-primary': focused,
                'text-neutral-darkest': !focused,
                'text-error': $store.getters.hasError(name, scope),
            }"
        >
            {{ label }}
        </label>
        <div class="relative flex cursor-pointer flex-col justify-center">
            <div
                tabindex="0"
                class="w-full appearance-none truncate rounded-lg border border-neutral-lighter py-2 pl-4 pr-12 text-neutral-darkest outline-none transition-all duration-300 md:border-2"
                :class="{
                    'border-primary': focused,
                    'border-error': $store.getters.hasError(name, scope),
                    'text-opacity-25': !value,
                }"
                @focus="focused = true"
                @blur="focused = false"
                @click="showPicker = true"
            >
                <span v-if="value">{{ value.format(format) }}</span>
                <span v-else>{{ format }}</span>
            </div>
            <Icon
                class="pointer-events-none absolute right-0 mr-4 transition-all duration-300"
                :class="{
                    'text-primary': focused,
                    'text-neutral-darkest': !focused,
                    'text-error': $store.getters.hasError(name, scope),
                }"
                :icon="['far', 'clock']"
            />
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="$store.getters.hasError(name, scope)"
                    class="absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-300"
                >
                    {{ $t($store.getters.getError(name, scope)) }}
                </div>
            </transition>
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <TimePicker
                    v-if="showPicker"
                    v-on-clickaway="hidePicker"
                    class="absolute bottom-0 z-10 translate-y-full transform"
                    :value="value"
                    :date="value"
                    @input="onInput"
                    @close="hidePicker"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import TimePicker from '@/components/input/TimePicker';
import { directive as onClickaway } from 'vue-clickaway';
import { VALIDATION_REMOVE_ERROR } from '@/constants';

@Component({
    components: {
        TimePicker,
    },
    props: {
        format: {
            default: 'HH:mm',
            type: String,
        },
        label: String,
        name: String,
        scope: {
            default: 'default',
            type: String,
        },
        value: Object,
        date: Object,
    },
    directives: {
        onClickaway: onClickaway,
    },
})
export default class InputDate extends Vue {
    focused = false;
    showPicker = false;

    onInput(value) {
        if (this.$store.getters.hasError(this.name, this.scope)) {
            this.$store.commit(VALIDATION_REMOVE_ERROR, {
                field: this.name,
                scope: this.scope,
            });
        }
        this.$emit('input', value);
    }

    hidePicker() {
        this.showPicker = false;
    }
}
</script>
