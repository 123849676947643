var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full text-left"},[(_vm.label)?_c('label',{staticClass:"text-sm font-bold transition-all duration-500",class:{
            'text-primary': _vm.focused,
            'text-neutral-darkest': !_vm.focused,
            'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"relative flex flex-col justify-center"},[_c('textarea',{staticClass:"h-16 w-full resize-none appearance-none rounded-lg border border-neutral-lighter px-4 py-2 font-light placeholder-neutral-darker outline-none transition-all duration-500 md:border-2",class:{
                'pl-12': _vm.icon,
                'border-primary': _vm.focused,
                'border-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
            },attrs:{"autocomplete":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder,"maxlength":"5000","rows":_vm.rows},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"input":_vm.onInput}}),(_vm.icon)?_c('Icon',{staticClass:"absolute left-0 ml-4 transition-all duration-500",class:{
                'text-primary': _vm.focused,
                'text-neutral-darkest': !_vm.focused,
                'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
            },attrs:{"icon":['far', _vm.icon]}}):_vm._e(),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.$store.getters.hasError(_vm.name, _vm.scope))?_c('div',{staticClass:"absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"},[_vm._v(" "+_vm._s(_vm.$store.getters.getError(_vm.name, _vm.scope))+" ")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }