var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full text-left"},[(_vm.label)?_c('label',{staticClass:"text-sm font-bold text-neutral-darkest transition-all duration-500"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"relative flex flex-col justify-center"},[_c('input',{staticClass:"h-12 w-full appearance-none rounded-lg border border-neutral-lighter px-4 py-3 font-light placeholder-neutral-lighter outline-none transition-all duration-500 md:border-2",class:[
                {
                    'pl-12': _vm.icon,
                    'border-primary': !_vm.readonly && _vm.focused,
                    'bg-neutral-lightest': _vm.readonly && !_vm.background,
                    'rounded-r-none': _vm.noRightBorderRadius,
                },
                _vm.background,
            ],attrs:{"name":_vm.name,"autocomplete":_vm.name,"type":_vm.inputType,"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.focused = true;
                _vm.$emit('focus');},"blur":function($event){_vm.focused = false;
                _vm.$emit('blur');},"input":_vm.onInput}}),(_vm.icon)?_c('Icon',{staticClass:"absolute left-0 ml-4 transition-all duration-500",class:{
                'text-primary': !_vm.readonly && _vm.focused,
                'text-neutral-darkest': !_vm.focused,
                'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope) || _vm.displayCustomError,
            },attrs:{"icon":['far', _vm.icon]}}):_vm._e(),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.type === 'password' && _vm.inputType === 'text')?_c('Icon',{staticClass:"absolute right-0 mr-4 cursor-pointer text-xl text-primary transition-opacity duration-500",attrs:{"icon":['far', 'eye']},on:{"click":_vm.toggleInputType}}):_vm._e()],1),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.type === 'password' && _vm.inputType === 'password')?_c('HideIcon',{staticClass:"absolute right-0 mr-4 cursor-pointer transition-opacity duration-500",on:{"click":_vm.toggleInputType}}):_vm._e()],1),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.$store.getters.hasError(_vm.name, _vm.scope))?_c('div',{staticClass:"absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"},[_vm._v(" "+_vm._s(_vm.$store.getters.getError(_vm.name, _vm.scope))+" ")]):_vm._e(),(_vm.displayCustomError)?_c('div',{staticClass:"absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }