var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full text-left"},[(_vm.label)?_c('label',{staticClass:"text-sm transition-all duration-500",class:{
            'text-primary': _vm.focused,
            'text-neutral-darkest': !_vm.focused,
            'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"relative flex cursor-pointer flex-col justify-center"},[_c('div',{staticClass:"w-full appearance-none truncate rounded-lg border border-neutral-lighter py-2 pl-4 pr-12 text-neutral-darkest outline-none transition-all duration-500 md:border-2",class:{
                'border-primary': _vm.focused,
                'border-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
                'text-opacity-25': !_vm.value,
            },attrs:{"tabindex":"0"},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"click":function($event){_vm.showPicker = true}}},[_vm._v(" "+_vm._s(_vm.value ? _vm.formattedValue : _vm.format)+" ")]),_c('Icon',{staticClass:"pointer-events-none absolute right-0 mr-4 transition-all duration-500",class:{
                'text-primary': _vm.focused,
                'text-neutral-darkest': !_vm.focused,
                'text-error': _vm.$store.getters.hasError(_vm.name, _vm.scope),
            },attrs:{"icon":['far', 'calendar']}}),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.$store.getters.hasError(_vm.name, _vm.scope))?_c('div',{staticClass:"absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"},[_vm._v(" "+_vm._s(_vm.$store.getters.getError(_vm.name, _vm.scope))+" ")]):_vm._e()]),_c('transition',{attrs:{"enter-class":"opacity-0","leave-to-class":"opacity-0"}},[(_vm.showPicker)?_c('DatePicker',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hidePicker),expression:"hidePicker"}],staticClass:"absolute bottom-0 z-10 translate-y-full transform rounded-3xl",attrs:{"value":_vm.value},on:{"input":_vm.onInput}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }