<template>
    <div
        class="new-event-popup"
        :class="[
            mobile
                ? 'new-event-popup--mobile left-1/2 -translate-x-1/2 -translate-y-full p-4'
                : 'new-event-popup--desktop top-0 translate-x-full',
        ]"
    >
        <button
            class="p-4 outline-none focus:outline-none"
            data-cy="new-slot-event"
            @click="$emit('open-modal', 'SlotModal')"
        >
            <div class="flex items-center justify-center p-3">
                <div class="mr-2 text-4xl text-primary">
                    <Icon :icon="['far', 'clock']" />
                </div>
                <div class="w-32 text-lg leading-tight">
                    {{ $t('professional.new_event.create_slot') }}
                </div>
            </div>
            <div class="px-8 py-2 text-sm leading-tight">
                {{ $t('professional.new_event.create_slot_description') }}
            </div>
        </button>
        <div class="px-6">
            <div class="w-full bg-neutral-lighter bg-opacity-50 pt-px"></div>
        </div>
        <button
            class="p-4 outline-none focus:outline-none"
            data-cy="new-client-event"
            @click="$emit('open-modal', 'EventModal')"
        >
            <div class="flex items-center justify-center p-3">
                <div class="mr-2 text-4xl text-primary">
                    <Icon :icon="['far', 'user']" />
                </div>
                <div class="w-32 text-lg leading-tight">
                    {{ $t('professional.new_event.create_event') }}
                </div>
            </div>
            <div class="px-8 py-2 text-sm leading-tight">
                {{ $t('professional.new_event.create_event_description') }}
            </div>
        </button>
        <div class="px-6">
            <div class="w-full bg-neutral-lighter bg-opacity-50 pt-px"></div>
        </div>
        <button
            class="p-4 outline-none focus:outline-none"
            data-cy="new-class-event"
            @click="$emit('open-modal', 'ClassModal')"
        >
            <div class="flex items-center justify-center p-3">
                <div class="mr-2 text-4xl text-primary">
                    <Icon :icon="['far', 'users']" />
                </div>
                <div class="w-32 text-lg leading-tight">
                    {{ $t('professional.new_event.create_class') }}
                </div>
            </div>
            <div class="px-8 py-2 text-sm leading-tight">
                {{ $t('professional.new_event.create_class_description') }}
            </div>
        </button>
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class NewEventPopup extends Vue {
    @Prop({ type: Boolean }) mobile;
    @Prop({ type: Object }) profile;
}
</script>

<style lang="postcss">
.new-event-popup {
    width: 20rem;
    @apply absolute z-30 transition-all duration-500;
    @apply transform rounded-lg bg-white text-center text-neutral-darkest shadow;
}

.new-event-popup--desktop {
    right: -1rem;
}

.new-event-popup--mobile {
    top: -0.5rem;
}
</style>
