import {
    GROUPS_ADD_GROUP,
    GROUPS_FETCH,
    GROUPS_SET,
    REQUEST,
    VALIDATION_CREATE_MESSAGE,
} from '@/constants';

export default {
    state: {
        groups: null,
    },
    getters: {},
    mutations: {
        [GROUPS_SET](state, groups) {
            state.groups = groups;
        },
    },
    actions: {
        async [GROUPS_FETCH]({ commit, dispatch }, params) {
            commit(GROUPS_SET, null);
            const response = await dispatch(REQUEST, {
                url: 'groups',
                params,
            });
            if (response.status === 200) {
                commit(GROUPS_SET, response.data.data);
            }
            return response;
        },
        async [GROUPS_ADD_GROUP]({ dispatch }, data) {
            const response = await dispatch(REQUEST, {
                method: 'post',
                url: 'groups',
                data,
            });
            if (response.status === 200) {
                dispatch(VALIDATION_CREATE_MESSAGE, {
                    type: 'success',
                    message: 'professional.clientbook.group_add_modal.success_message',
                });
                dispatch(GROUPS_FETCH);
            }
            return response;
        },
    },
};
