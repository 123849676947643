<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
    <div>
        <h1>App mount failure</h1>
        <p>The app failed to lauch, see the browser console for more details.</p>
        <p>
            If reloading the browser does not fix the issue please contact the site administrator.
        </p>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class AppMountFailed extends Vue {}
</script>
