import {
    REGISTER_SET_EMAIL,
    REGISTER_SET_EMAIL_CONFIRMATION,
    REGISTER_SET_AGREE,
    REGISTER_CLEAR,
    REGISTER_SET_USERNAME,
    REGISTER_SET_PASSWORD,
    REGISTER_SET_PASSWORD_CONFIRMATION,
} from '@/constants';

export default {
    state: {
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        username: '',
        agree: false,
    },
    mutations: {
        [REGISTER_SET_EMAIL](state, email) {
            state.email = email;
        },
        [REGISTER_SET_EMAIL_CONFIRMATION](state, email_confirmation) {
            state.email_confirmation = email_confirmation;
        },
        [REGISTER_SET_USERNAME](state, username) {
            state.username = username;
        },
        [REGISTER_SET_PASSWORD](state, password) {
            state.password = password;
        },
        [REGISTER_SET_PASSWORD_CONFIRMATION](state, password_confirmation) {
            state.password_confirmation = password_confirmation;
        },
        [REGISTER_SET_AGREE](state, agree) {
            state.agree = agree;
        },
        [REGISTER_CLEAR](state) {
            state.email = '';
            state.email_confirmation = '';
            state.password = '';
            state.username = '';
            state.agree = false;
        },
    },
};
