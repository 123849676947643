<template>
    <div ref="banner" class="fixed top-0 h-full w-full bg-black bg-opacity-25">
        <div class="fixed bottom-0 w-full bg-white p-4 shadow transition-all duration-500">
            <div class="flex flex-wrap items-center justify-center text-center">
                <span class="p-3">
                    {{ $t('cookie_banner.message') }}
                </span>
                <div class="flex flex-none flex-col p-3 md:flex-row">
                    <button data-cy="understand-btn" class="btn mx-4" @click="confirm">
                        {{ $t('cookie_banner.understand') }}
                    </button>
                    <router-link
                        tag="button"
                        class="link-secondary m-4 md:my-0"
                        :to="{
                            name: isThemeClient
                                ? 'ClientPrivacyPolicy'
                                : 'ProfessionalPrivacyPolicy',
                        }"
                    >
                        {{ $t('cookie_banner.more_info') }}
                    </router-link>
                </div>
            </div>
        </div>
        <a tabindex="0" class="fixed top-0 overflow-hidden" @focus="$refs.cookie.focus()" />
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class CookieBanner extends Vue {
    confirm() {
        this.$emit('confirm');
    }

    get isThemeClient() {
        return this.$store.getters.isThemeClient;
    }
}
</script>
