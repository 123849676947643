<template>
    <Dialog :title="$t('professional.delete_slot_modal.title')" @submit="onSubmit" @close="close">
        <div class="p-6">
            <div v-if="event && event.recurrence_rule" class="mb-6 flex items-center">
                <div class="mr-2 flex-none">
                    {{ $t('professional.recurrence_update_modes.when_confirming_delete') }}
                </div>
                <InputSelect
                    v-model="recurrenceUpdateMode"
                    name="update_mode"
                    :options="recurrenceUpdateModes"
                />
            </div>

            <div class="flex items-center justify-center">
                <button class="btn mx-3 justify-self-center p-3 text-center" @click.prevent="close">
                    {{ $t('professional.delete_slot_modal.back') }}
                </button>
                <button class="btn mx-3 justify-self-center p-3 text-center">
                    {{ $t('professional.delete_slot_modal.confirm') }}
                </button>
            </div>
        </div>
    </Dialog>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import Dialog from '@/components/modals/Dialog';
import InputSelect from '@/components/input/InputSelect';
import { REQUEST, VALIDATION_CREATE_MESSAGE } from '@/constants';

@Component({
    components: {
        InputSelect,
        Dialog,
    },
})
export default class SlotDeleteDialog extends Vue {
    recurrenceUpdateMode = 'this';

    @Prop() event;
    @Prop() callback;

    close() {
        this.$root.$emit('modal-close');
    }

    async onSubmit() {
        if (this.loading) return;
        this.loading = true;
        const response = await this.$store.dispatch(REQUEST, {
            method: 'post',
            url: `availability-slots/${this.event.id}/cancel`,
            data: {
                update_mode: this.recurrenceUpdateMode,
            },
        });
        this.loading = false;

        if (response.status === 200) {
            this.$store.dispatch(VALIDATION_CREATE_MESSAGE, {
                type: 'success',
                message: 'professional.delete_slot_modal.slot_cancelled',
            });
            this.callback();
            this.close();
        }
    }

    get recurrenceUpdateModes() {
        return [
            { value: 'this', label: this.$t('professional.recurrence_update_modes.this') },
            { value: 'future', label: this.$t('professional.recurrence_update_modes.future') },
            // { value: 'all', label: this.$t('professional.recurrence_update_modes.all') },
        ];
    }
}
</script>
