<template>
    <div
        :class="[
            'flex items-center justify-center text-primary',
            inlined ? 'flex-row' : 'flex-col',
        ]"
    >
        <WhiteText v-if="darkMode" />
        <BlackText v-if="!darkMode" />
    </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import BrandSvg from '@/assets/img/brand.svg';
import BlackText from '@/assets/img/myday_logo_black_text.svg';
import WhiteText from '@/assets/img/myday_logo_white_text.svg';
@Component({
    components: {
        BrandSvg,
        BlackText,
        WhiteText,
    },
})
export default class Logo extends Vue {
    @Prop({ type: Boolean, default: false }) inlined;
    @Prop({ type: Boolean, default: false }) darkMode;
}
</script>
