import {
    REQUEST,
    PROFESSIONALS_SET,
    PROFESSIONALS_FETCH,
    PROFESSIONALS_VOUCHERS_SET,
    PROFESSIONALS_VOUCHERS_FETCH,
} from '@/constants';

export default {
    state: {
        professionals: {},
        vouchers: {},
    },
    mutations: {
        [PROFESSIONALS_SET](state, professionals) {
            // Put the professionals in dictionary for easy lookup
            if (Array.isArray(professionals)) {
                state.professionals = professionals.reduce(
                    (dict, professional) => ((dict[professional.id] = professional), dict),
                    {},
                );
            } else {
                state.professionals = {};
            }
        },
        [PROFESSIONALS_VOUCHERS_SET](state, vouchers) {
            state.vouchers = vouchers;
        },
    },
    actions: {
        async [PROFESSIONALS_FETCH]({ commit, dispatch }, params) {
            commit(PROFESSIONALS_SET, {});
            const response = await dispatch(REQUEST, {
                url: 'professionals',
                params,
            });
            if (response.status === 200) {
                commit(PROFESSIONALS_SET, response.data.data);
                dispatch(PROFESSIONALS_VOUCHERS_FETCH);
            }
            return response;
        },
        async [PROFESSIONALS_VOUCHERS_FETCH]({ state, rootState, commit, dispatch }) {
            commit(PROFESSIONALS_VOUCHERS_SET, {});
            const response = await dispatch(REQUEST, {
                url: 'vouchers',
            });
            if (response.status === 200) {
                const vouchers = {};
                const unusedVouchers = response.data.data;
                const durations = rootState.vouchers.durations;
                Object.values(state.professionals).forEach((pro) => {
                    vouchers[pro.id] = rootState.vouchers.voucherTypes?.reduce(
                        (typesDict, type) => {
                            typesDict[type.id] = {
                                type,
                                totalCount: countVouchersByType(unusedVouchers, pro.id, type.id),
                                counts: durations.reduce((durationsDict, duration) => {
                                    durationsDict[duration.value] = countVouchersByTypeAndDuration(
                                        unusedVouchers,
                                        pro.id,
                                        type.id,
                                        duration.value,
                                    );
                                    return durationsDict;
                                }, {}),
                            };
                            return typesDict;
                        },
                        {},
                    );
                });
                commit(PROFESSIONALS_VOUCHERS_SET, vouchers);
            }
            return response;
        },
    },
};

const countVouchersByType = (unusedVouchers, proId, typeId) => {
    return unusedVouchers.reduce(
        (count, voucher) =>
            voucher.professional_id === proId && voucher.type?.id === typeId
                ? count + voucher.value
                : count,
        0,
    );
};

const countVouchersByTypeAndDuration = (unusedVouchers, proId, typeId, duration) => {
    return unusedVouchers.reduce(
        (count, voucher) =>
            voucher.professional_id === proId &&
            voucher.type?.id === typeId &&
            voucher.duration === duration
                ? count + voucher.value
                : count,
        0,
    );
};
