<template>
    <transition enter-class="opacity-0" leave-to-class="opacity-0">
        <template v-if="show && isPageDisabled">
            <cookie-banner @confirm="confirm"></cookie-banner>
        </template>
    </transition>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import CookieBanner from './CookieBanner.vue';

@Component({
    components: {
        CookieBanner,
    },
})
export default class CookieNotification extends Vue {
    show = false;
    type = 'banner';

    created() {
        var local = this.getLocalStorage();
        if (this.$store.state.config.rules.cookies.enabled && !local) {
            this.show = true;
            this.type = this.$store.state.config.rules.cookies.type;
        }
    }

    getLocalStorage() {
        var item = localStorage.getItem('cookies');
        if (!item) {
            return false;
        } else {
            return JSON.parse(item);
        }
    }

    mounted() {
        if (this.type === 'modal') {
            this.$modal.show('cookie-modal');
        }
    }

    confirm() {
        localStorage.setItem('cookies', true);
        this.show = false;
    }

    get isPageDisabled() {
        const cookieFreePages = [
            'ClientPrivacyPolicy',
            'ClientTermsAndConditions',
            'ProfessionalPrivacyPolicy',
            'ProfessionalTermsAndConditions',
        ];
        const { name } = this.$route;
        return !cookieFreePages.includes(name);
    }
}
</script>
