<template>
    <div class="relative w-full text-left">
        <label
            v-if="label"
            class="text-sm transition-all duration-500"
            :class="{
                'text-primary': focused,
                'text-neutral-darkest': !focused,
                'text-error': $store.getters.hasError(name, scope),
            }"
        >
            {{ label }}
        </label>
        <div class="relative flex flex-col justify-center text-sm">
            <div class="flex flex-wrap">
                <div
                    v-for="(tag, index) in value"
                    :key="tag + index"
                    class="m-1 flex items-center rounded-lg bg-neutral-lighter bg-opacity-50 p-1 px-2"
                >
                    <span>{{ tag }}</span>
                    <button type="button" class="px-1 focus:outline-none" @click="removeTag(tag)">
                        <Icon :icon="['far', 'xmark']" />
                    </button>
                </div>
            </div>
            <input
                v-model="text"
                type="text"
                class="w-full appearance-none border-b border-neutral-lighter px-4 py-2 font-light placeholder-neutral-darker outline-none transition-all duration-500"
                :class="{
                    'pl-12': icon,
                    'border-primary': focused,
                    'border-error': $store.getters.hasError(name, scope),
                }"
                :placeholder="placeholder"
                @focus="focused = true"
                @blur="onBlur"
                @input="onInput"
                @keydown.delete="removeLastTag()"
            />
            <Icon
                v-if="icon"
                class="absolute left-0 ml-4 transition-all duration-500"
                :class="{
                    'text-primary': focused,
                    'text-neutral-darkest': !focused,
                    'text-error': $store.getters.hasError(name, scope),
                }"
                :icon="['far', icon]"
            />
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="$store.getters.hasError(name, scope)"
                    class="absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"
                >
                    {{ $store.getters.getError(name, scope) }}
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { VALIDATION_REMOVE_ERROR } from '@/constants';

@Component({
    props: {
        icon: String,
        label: String,
        placeholder: String,
        name: String,
        scope: {
            default: 'default',
            type: String,
        },
        value: Array,
        validate: String,
    },
})
export default class InputText extends Vue {
    focused = false;
    text = '';

    onInput(e) {
        if (this.$store.getters.hasError(this.name, this.scope)) {
            this.$store.commit(VALIDATION_REMOVE_ERROR, {
                field: this.name,
                scope: this.scope,
            });
        }

        if (e.inputType === 'insertFromPaste' || e.data === ',') {
            this.parseTags(e);
        }
    }

    onBlur(e) {
        this.focused = false;
        this.parseTags(e);
    }

    parseTags(e) {
        let tags = e.target.value.split(',');
        tags = tags.filter((tag) => tag.length);
        tags = tags.filter((tag) => this.validateTag(tag));
        if (!tags.length) return;
        this.$emit('input', Array.from(new Set([...this.value, ...tags])));
        this.text = '';
    }

    validateTag(tag) {
        switch (this.validate) {
            case 'phone':
                var phoneRegEx = /^\+(?:[0-9] ?){6,14}[0-9]$/;
                return phoneRegEx.test(tag);
            case 'email':
                var emailRegEx = /\S+@\S+\.\S+/;
                return emailRegEx.test(tag);
            default:
                return true;
        }
    }

    removeLastTag() {
        if (this.text.length) return;
        if (!this.value.length) return;
        this.removeTag(this.value[this.value.length - 1]);
    }

    removeTag(tag) {
        this.$emit(
            'input',
            this.value.filter((v) => v !== tag),
        );
    }
}
</script>
