<template>
    <div class="relative w-full text-left">
        <label
            v-if="label"
            class="text-sm font-bold transition-all duration-500"
            :class="{
                'text-primary': focused,
                'text-neutral-darkest': !focused,
                'text-error': $store.getters.hasError(name, scope),
            }"
        >
            {{ label }}
        </label>
        <div class="relative flex flex-col justify-center">
            <textarea
                class="h-16 w-full resize-none appearance-none rounded-lg border border-neutral-lighter px-4 py-2 font-light placeholder-neutral-darker outline-none transition-all duration-500 md:border-2"
                :class="{
                    'pl-12': icon,
                    'border-primary': focused,
                    'border-error': $store.getters.hasError(name, scope),
                }"
                :autocomplete="name"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                maxlength="5000"
                :rows="rows"
                @focus="focused = true"
                @blur="focused = false"
                @input="onInput"
            ></textarea>
            <Icon
                v-if="icon"
                class="absolute left-0 ml-4 transition-all duration-500"
                :class="{
                    'text-primary': focused,
                    'text-neutral-darkest': !focused,
                    'text-error': $store.getters.hasError(name, scope),
                }"
                :icon="['far', icon]"
            />
            <transition enter-class="opacity-0" leave-to-class="opacity-0">
                <div
                    v-if="$store.getters.hasError(name, scope)"
                    class="absolute bottom-0 left-0 max-w-full translate-y-full transform truncate text-xs text-error transition-all duration-500"
                >
                    {{ $store.getters.getError(name, scope) }}
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { VALIDATION_REMOVE_ERROR } from '@/constants';

@Component({
    props: {
        icon: String,
        label: String,
        name: String,
        placeholder: String,
        rows: {
            default: '5',
            type: String,
        },
        scope: {
            default: 'default',
            type: String,
        },
        type: {
            default: 'text',
            type: String,
        },
        value: [String, Number],
    },
})
export default class InputTextArea extends Vue {
    focused = false;

    onInput(e) {
        if (this.$store.getters.hasError(this.name, this.scope)) {
            this.$store.commit(VALIDATION_REMOVE_ERROR, {
                field: this.name,
                scope: this.scope,
            });
        }
        this.$emit('input', e.target.value);
    }
}
</script>
