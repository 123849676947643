import { EMAILS_UNSUBSCRIBE, REQUEST } from '@/constants';

export default {
    actions: {
        async [EMAILS_UNSUBSCRIBE]({ dispatch }, data) {
            return await dispatch(REQUEST, {
                method: 'post',
                url: 'newsletter/unsubscribe',
                data,
            });
        },
    },
};
