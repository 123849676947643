<template>
    <transition-group
        enter-class="translate-x-full opacity-0"
        leave-to-class="translate-x-full opacity-0"
        class="pointer-events-none fixed right-0 top-0 z-50 flex w-full flex-col items-end overflow-hidden p-2 sm:bottom-0 sm:flex-col-reverse"
    >
        <div
            v-for="item in messages"
            :key="item.id"
            class="pointer-events-auto relative left-0 ml-16 mt-2 flex transform items-center rounded border-l-4 bg-white p-4 shadow transition-all duration-500 md:ml-0"
            :class="{
                'border-error': item.type === 'error',
                'border-success': item.type === 'success',
            }"
        >
            <Icon
                :icon="['far', icon[item.type]]"
                size="lg"
                :class="{
                    'text-error': item.type === 'error',
                    'text-success': item.type === 'success',
                }"
            />
            <div class="pl-4 leading-tight">
                <div class="pb-1 font-bold">{{ $t('common.' + item.type) }}</div>
                <span>{{ $t(item.message) }}</span>
            </div>
            <Icon :icon="['far', 'xmark']" class="mx-3" @click="hide(item)" />
        </div>
    </transition-group>
</template>

<script>
import { VALIDATION_REMOVE_MESSAGE } from '@/constants';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ValidationMessages extends Vue {
    icon = {
        error: 'circle-xmark',
        success: 'circle-check',
    };

    get messages() {
        return this.$store.state.validation.messages;
    }

    hide(item) {
        this.$store.commit(VALIDATION_REMOVE_MESSAGE, item.id);
    }
}
</script>
