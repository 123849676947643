import { PWAINSTALLPROMPT_EVENT_SET } from '@/constants';

export default {
    state: {
        beforeInstallPromptEvent: null,
    },
    getters: {},
    mutations: {
        [PWAINSTALLPROMPT_EVENT_SET](state, beforeInstallPromptEvent) {
            state.beforeInstallPromptEvent = beforeInstallPromptEvent;
        },
    },
};
