import Vue from 'vue';
import {
    VALIDATION_SET_MESSAGE,
    VALIDATION_REMOVE_MESSAGE,
    VALIDATION_SET_BAG,
    VALIDATION_REMOVE_BAG,
    VALIDATION_CLEAR_BAGS,
    VALIDATION_SET_ERROR,
    VALIDATION_REMOVE_ERROR,
    VALIDATION_CREATE_MESSAGE,
    HELPERS_SLEEP,
} from '@/constants';

export default {
    state: {
        messages: [],
        bags: {},
    },
    getters: {
        getError:
            (state) =>
            (field, scope = 'default') => {
                if (!state.bags[scope]) {
                    return null;
                }
                const error = state.bags[scope].find(
                    (e) => e.field === field || e.field.startsWith(field),
                );
                return error ? error.message.toLowerCase() : null;
            },
        hasError:
            (state) =>
            (field, scope = 'default') => {
                return (
                    state.bags[scope] &&
                    state.bags[scope].some((e) => e.field === field || e.field.startsWith(field))
                );
            },
    },
    mutations: {
        [VALIDATION_SET_MESSAGE](state, message) {
            state.messages.unshift(message);
        },
        [VALIDATION_REMOVE_MESSAGE](state, id) {
            state.messages = state.messages.filter((message) => message.id !== id);
        },
        [VALIDATION_SET_BAG](state, { bag, scope = 'default' }) {
            Vue.set(state.bags, scope, bag);
        },
        [VALIDATION_CLEAR_BAGS](state) {
            state.bags = {};
        },
        [VALIDATION_REMOVE_BAG](state, scope) {
            Vue.delete(state.bags, scope);
        },
        [VALIDATION_SET_ERROR](state, { error, scope = 'default' }) {
            // create a bag for this scope if doesn't exist
            if (!state.bags[scope]) state.bags[scope] = [];
            state.bags[scope].push(error);
        },
        [VALIDATION_REMOVE_ERROR](state, { field, scope = 'default' }) {
            if (state.bags[scope]) {
                const bag = state.bags[scope].filter((e) => e.field !== field);
                Vue.set(state.bags, scope, bag);
            }
            if (state.bags[scope] && Object.keys(state.bags[scope]).length < 1) {
                Vue.delete(state.bags, scope);
            }
        },
    },
    actions: {
        async [VALIDATION_CREATE_MESSAGE]({ commit, dispatch }, message) {
            const id = Math.random().toString(36).substr(2, 9);
            commit(VALIDATION_SET_MESSAGE, { id, ...message });
            await dispatch(HELPERS_SLEEP, 6000);
            commit(VALIDATION_REMOVE_MESSAGE, id);
        },
    },
};
