import { CLIENT } from '@/constants';

export default {
    path: '/',
    component: () => import('@/pages/guest/Wrapper'),
    children: [
        {
            path: '@:username',
            name: 'PublicProfessionalProfile',
            meta: {
                order: 1,
                theme: CLIENT,
                forceRouteTheme: true,
            },
            component: () => import('@/pages/guest/PublicProfessionalProfile'),
        },
        {
            path: 'unsubscribe',
            name: 'Unsubscribe',
            meta: {
                order: 2,
                theme: null,
            },
            component: () => import('@/pages/common/Unsubscribe'),
        },
    ],
};
