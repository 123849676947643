var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded border border-neutral-lighter bg-white transition-all duration-500"},[_c('div',{staticClass:"flex h-48"},[_c('div',{ref:"hours",staticClass:"w-16 overflow-x-auto text-center md:w-24",attrs:{"data-cy":"time-picker-hours"}},_vm._l((_vm.hours),function(hour){return _c('div',{key:hour,staticClass:"cursor-pointer p-2 transition-all duration-300",class:[
                    hour === _vm.selectedHour
                        ? 'bg-primary bg-opacity-75'
                        : 'hover:bg-primary hover:bg-opacity-25',
                ],on:{"click":function($event){return _vm.selectHour(hour)}}},[_vm._v(" "+_vm._s(hour)+" ")])}),0),_c('div',{staticClass:"w-16 overflow-x-auto text-center md:w-20",attrs:{"data-cy":"time-picker-minutes"}},_vm._l((_vm.minutes),function(minutes){return _c('div',{key:minutes,staticClass:"cursor-pointer p-2 transition-all duration-300",class:[
                    minutes === _vm.selectedMinutes
                        ? 'bg-primary bg-opacity-75'
                        : 'hover:bg-primary hover:bg-opacity-25',
                ],on:{"click":function($event){return _vm.selectMinutes(minutes)}}},[_vm._v(" "+_vm._s(minutes)+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }