var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-event-popup",class:[
        _vm.mobile
            ? 'new-event-popup--mobile left-1/2 -translate-x-1/2 -translate-y-full p-4'
            : 'new-event-popup--desktop top-0 translate-x-full',
    ]},[_c('button',{staticClass:"p-4 outline-none focus:outline-none",attrs:{"data-cy":"new-slot-event"},on:{"click":function($event){return _vm.$emit('open-modal', 'SlotModal')}}},[_c('div',{staticClass:"flex items-center justify-center p-3"},[_c('div',{staticClass:"mr-2 text-4xl text-primary"},[_c('Icon',{attrs:{"icon":['far', 'clock']}})],1),_c('div',{staticClass:"w-32 text-lg leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('professional.new_event.create_slot'))+" ")])]),_c('div',{staticClass:"px-8 py-2 text-sm leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('professional.new_event.create_slot_description'))+" ")])]),_vm._m(0),_c('button',{staticClass:"p-4 outline-none focus:outline-none",attrs:{"data-cy":"new-client-event"},on:{"click":function($event){return _vm.$emit('open-modal', 'EventModal')}}},[_c('div',{staticClass:"flex items-center justify-center p-3"},[_c('div',{staticClass:"mr-2 text-4xl text-primary"},[_c('Icon',{attrs:{"icon":['far', 'user']}})],1),_c('div',{staticClass:"w-32 text-lg leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('professional.new_event.create_event'))+" ")])]),_c('div',{staticClass:"px-8 py-2 text-sm leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('professional.new_event.create_event_description'))+" ")])]),_vm._m(1),_c('button',{staticClass:"p-4 outline-none focus:outline-none",attrs:{"data-cy":"new-class-event"},on:{"click":function($event){return _vm.$emit('open-modal', 'ClassModal')}}},[_c('div',{staticClass:"flex items-center justify-center p-3"},[_c('div',{staticClass:"mr-2 text-4xl text-primary"},[_c('Icon',{attrs:{"icon":['far', 'users']}})],1),_c('div',{staticClass:"w-32 text-lg leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('professional.new_event.create_class'))+" ")])]),_c('div',{staticClass:"px-8 py-2 text-sm leading-tight"},[_vm._v(" "+_vm._s(_vm.$t('professional.new_event.create_class_description'))+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-6"},[_c('div',{staticClass:"w-full bg-neutral-lighter bg-opacity-50 pt-px"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-6"},[_c('div',{staticClass:"w-full bg-neutral-lighter bg-opacity-50 pt-px"})])
}]

export { render, staticRenderFns }