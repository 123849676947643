import { ONBOARDING_SHOW_STATE_SET, ONBOARDING_SLIDE_SET } from '@/constants';

export default {
    state: {
        show: false,
        onboardingSlide: null,
    },
    mutations: {
        [ONBOARDING_SHOW_STATE_SET](state, showState) {
            state.show = showState;
        },
        [ONBOARDING_SLIDE_SET](state, onboardingSlide) {
            state.onboardingSlide = onboardingSlide;
        },
    },
};
