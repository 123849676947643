<template>
    <div
        class="rounded border border-neutral-lighter bg-white p-3 transition-all duration-500"
        data-cy="date-picker"
    >
        <div class="flex items-center justify-between">
            <button type="button" class="btn--bordered text-sm" @click="previousMonth">
                <Icon :icon="['far', 'chevron-left']" />
            </button>
            <div class="px-2">
                <button type="button" class="mx-2 outline-none focus:outline-none">
                    {{ displayedDate.format('MMMM') }}
                </button>
                <button type="button" class="mx-2 outline-none focus:outline-none">
                    {{ displayedDate.format('YYYY') }}
                </button>
            </div>
            <button type="button" class="btn--bordered text-sm" @click="nextMonth">
                <Icon :icon="['far', 'chevron-right']" />
            </button>
        </div>
        <div class="flex items-center pb-2 pt-4">
            <div
                v-for="day in ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']"
                :key="`${day}day`"
                class="w-10 text-center font-bold text-neutral-darkest text-opacity-50"
            >
                {{ day }}
            </div>
        </div>
        <div class="flex flex-wrap" style="width: 17.5rem">
            <div
                v-for="missing in displayedDate.day()"
                :key="`${missing}missing`"
                class="w-10 pt-10"
            ></div>
            <div v-for="date in lastDate.date()" :key="`${date}date`" class="relative w-10 pt-10">
                <div
                    class="absolute left-1/2 top-1/2 flex h-8 w-8 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer flex-col items-center justify-center rounded-full border border-white"
                    :class="{
                        'border-primary text-primary':
                            date !== selectedDate.date() &&
                            date === today.date() &&
                            displayedDate.month() === today.month() &&
                            displayedDate.year() === today.year(),
                        'border-primary bg-primary text-white':
                            date === selectedDate.date() &&
                            displayedDate.month() === selectedDate.month() &&
                            displayedDate.year() === selectedDate.year(),
                    }"
                    @click="selectDate(date)"
                >
                    {{ date }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment-timezone';

@Component({
    props: {
        value: Object,
    },
})
export default class DatePicker extends Vue {
    today = moment();
    selectedDate = null;
    displayedDate = null;

    created() {
        this.selectedDate = this.value ? moment(this.value) : moment();
        this.displayedDate = moment(this.selectedDate).startOf('month');
    }

    get lastDate() {
        return moment(this.displayedDate).endOf('month');
    }

    nextMonth() {
        this.displayedDate = moment(this.displayedDate).add(1, 'months');
    }

    previousMonth() {
        this.displayedDate = moment(this.displayedDate).subtract(1, 'months');
    }

    selectDate(date) {
        this.selectedDate = moment(this.displayedDate).startOf('month').date(date);
        this.$emit('input', this.selectedDate);
    }
}
</script>
