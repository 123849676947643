<template>
    <transition enter-class="opacity-0" leave-to-class="opacity-0">
        <div v-if="showBanner" class="fixed top-0 z-30 h-full w-full bg-black bg-opacity-25">
            <div class="fixed bottom-0 w-full bg-white p-4 shadow transition-all duration-500">
                <div class="flex flex-wrap items-center justify-center text-center">
                    <span class="p-3">
                        {{ $t(`online_status.${status}`) }}
                    </span>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class OfflineNotification extends Vue {
    isOnline = navigator.onLine;
    showBanner = false;

    @Watch('isOnline')
    onIsOnlineChanged(newValue) {
        this.showBanner = true;
        newValue &&
            setTimeout(() => {
                this.showBanner = false;
            }, 900);
    }

    get status() {
        return this.isOnline ? 'online' : 'offline';
    }

    updateOnlineStatus({ type }) {
        this.isOnline = type === 'online';
    }

    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    }

    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
}
</script>
