var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative mx-auto rounded-full bg-primary",class:{
        'h-8 w-8': _vm.size === 'xs',
        'h-12 w-12': _vm.size === 'small',
        'h-16 w-16': _vm.size === 'medium',
        'h-24 w-24': _vm.size === 'large',
        'h-32 w-32': _vm.size === 'xl',
    }},[(!_vm.profile || !_vm.profile.avatar)?_c('span',{staticClass:"absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform font-bold text-white",class:{
            'text-base': _vm.size === 'xs',
            'text-2xl': _vm.size === 'small',
            'text-3xl': _vm.size === 'medium',
            'text-4xl': _vm.size === 'large',
            'text-5xl': _vm.size === 'xl',
        }},[_vm._v(" "+_vm._s(_vm.initials)+" ")]):_c('img',{staticClass:"h-full w-full rounded-full object-cover",attrs:{"src":_vm.profile.avatar}})])
}
var staticRenderFns = []

export { render, staticRenderFns }