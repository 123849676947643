<template>
    <div class="rounded border border-neutral-lighter bg-white transition-all duration-500">
        <div class="flex h-48">
            <div
                ref="hours"
                class="w-16 overflow-x-auto text-center md:w-24"
                data-cy="time-picker-hours"
            >
                <div
                    v-for="hour in hours"
                    :key="hour"
                    class="cursor-pointer p-2 transition-all duration-300"
                    :class="[
                        hour === selectedHour
                            ? 'bg-primary bg-opacity-75'
                            : 'hover:bg-primary hover:bg-opacity-25',
                    ]"
                    @click="selectHour(hour)"
                >
                    {{ hour }}
                </div>
            </div>
            <div class="w-16 overflow-x-auto text-center md:w-20" data-cy="time-picker-minutes">
                <div
                    v-for="minutes in minutes"
                    :key="minutes"
                    class="cursor-pointer p-2 transition-all duration-300"
                    :class="[
                        minutes === selectedMinutes
                            ? 'bg-primary bg-opacity-75'
                            : 'hover:bg-primary hover:bg-opacity-25',
                    ]"
                    @click="selectMinutes(minutes)"
                >
                    {{ minutes }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment-timezone';

@Component({
    props: {
        value: Object,
        date: Object,
    },
})
export default class TimePicker extends Vue {
    hours = Array(24)
        .fill()
        .map((_, i) => ('0' + i).slice(-2));
    minutes = ['00', '15', '30', '45'];

    selectedHour = null;
    selectedMinutes = null;

    created() {
        this.selectedHour = this.value ? this.value.format('HH') : moment().format('HH');
        this.selectedMinutes = this.value ? this.value.format('mm') : '00';
    }

    mounted() {
        this.$refs.hours.scrollTop = this.selectedHour * 35;
    }

    selectHour(hour) {
        this.selectedHour = hour;
        this.emitValue();
    }

    selectMinutes(minutes) {
        this.selectedMinutes = minutes;
        this.$emit('close');
        this.emitValue();
    }

    emitValue() {
        this.$emit(
            'input',
            // Use the date part from the date prop or the current date.
            moment(this.date || moment()).set({
                hour: this.selectedHour,
                minutes: this.selectedMinutes,
            }),
        );
    }
}
</script>
